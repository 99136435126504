import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-basic',
  template: `
  <p>
    basic works!
  </p>
  `,
  styles: [
  ]
})
export class BasicComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
