import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// RouterModule & Routes activate routing service in Angular
import { RouterModule, Routes } from '@angular/router';

// Include components for which router service to be activated
import { HomeComponent } from './component/home.component'
import { BasicComponent } from './component/basic.component'
import { ImageComponent } from './component/image.component'
import { AdvancedComponent } from './component/advanced.component'
import { LiveChatComponent } from './component/live-chat.component'
import { CustomModalComponent } from './component/custom-modal.component'
import { FormComponent } from './component/form.component'

// Routes array define component along with the path name for url
const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'popup-basic', component: BasicComponent },
  { path: 'popup-image', component: ImageComponent },
  { path: 'popup-advanced', component: AdvancedComponent },
  { path: 'live-chat', component: LiveChatComponent },
  { path: 'custom-modal', component: CustomModalComponent },
  { path: 'form', component: FormComponent },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})

export class AppRoutingModule { }
