import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-image',
  template: `
    <p>
      image works!
    </p>
  `,
  styles: [
  ]
})
export class ImageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
