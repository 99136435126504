<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="collapse navbar-collapse justify-content-center">
    <ul class="nav justify-content-center">
      <li class="nav-item">
        <a class="nav-link" routerLink="/" routerLinkActive="active">
          Strona główna
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/popup-basic" routerLinkActive="active">
          Popupy basic
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/popup-image" routerLinkActive="active">
          Popupy image
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/popup-advanced" routerLinkActive="active">
          Popupy advanced
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/live-chat" routerLinkActive="active">
          live Chat
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/custom-modal" routerLinkActive="active">
          Custom modal
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/form" routerLinkActive="active">
          Form
        </a>
      </li>
    </ul>
  </div>
</nav>

<div class="container" style="padding: 30px 0">
  <router-outlet></router-outlet>
</div>
