import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form',
  template: `
    <p>
      form-basic works!
    </p>
    <iframe id="smIframe-ipaboldx3f7jkb35" src="https://hekate.salesmanago.pl/ms/sid/0fjy7wimjpske0e7/ipaboldx3f7jkb35.htm" frameborder="0" style="border:none;width:100%"></iframe>
    <p>
      form-advanced works!
    </p>
    <div id="salesmanago-Iframe_ca92afae-b4c8-4d3c-904e-866e0f900316"></div>
  `,
  styles: [
  ]
})
export class FormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
